import shoe1 from "../images/shoe1.jpg";
import shoe2 from "../images/shoe2.jpg";
import shoe3 from "../images/shoe3.jpg";
import shoe4 from "../images/shoe4.jpg";

export const sliderData = [
  {
    id: 1,
    img: shoe1,
    text: "Summers SALE up to 50% OFF what are you wating for",
  },
  {
    id: 2,
    img: shoe2,
    text: "AUTUMN is coming, choose what suits you THE BEST",
  },
  {
    id: 3,
    img: shoe3,
    text: "Make your feet as comfortable as walking on the beach",
  },
  {
    id: 4,
    img: shoe4,
    text: "Choose between basketball and fashion or choose both",
  },
];
